@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anuphan:wght@300&display=swap");

$logo-font: 'Raleway', sans-serif;
$text-font: 'Anuphan', sans-serif;
$main-logo-font: 'Skia';
$background-color: #fbf6ee;
$text-color: #01110a;
$button-color: #588b8b;
$wrapper-color: #ffd485;
$content-color: #ecd3ac;
$primary-button-color: #90C4CF;
$primary-button-hover-color: #4793A3;

.page-wrapper {
    width: 100vw;
    background-color: transparent;
    margin-top: 9rem;
    justify-content: center;
    min-height: 70vh;
}

@media only screen and (max-width: 750px) {
    .page-wrapper {
        width: 100vw;
        background-color: transparent;
        margin-top: 2rem;
        justify-content: center;
        overflow-y: auto;
    }
}

#dropdown-no-caret::after {
    display: none;
}

#bootstrap-dropdown-override div {
    --bs-dropdown-padding-y: 0rem;
    --bs-dropdown-bg: rgba(0, 0, 0, 0);
    --bs-dropdown-border-radius: 10px;
}

#chapterButton {
    text-align: left;
    color: $text-color;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    font-size: larger;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;

    &:hover {
        color: whitesmoke;
        background-color: $content-color;
    }
}

.main-logo {
    font-family: $logo-font;
    color: $text-color;
    font-weight: 600;
    font-size: large;
}

#chapterDropdownItem {
    padding: 0.25rem .5rem 0.5rem 1rem;
}

#TOCButton {
    --bs-btn-border-width: 0;
    --bs-btn-bg: #e76f51;
    --bs-btn-color: white;
    --bs-btn-hover-bg: #e76f51;
    --bs-btn-hover-color: white;
    --bs-btn-active-bg: #e76f51;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.main-logo-full {
    font-family: $main-logo-font;
    color: $text-color;
    text-align: start;
    padding-top: .5rem;
    padding-left: .5rem;
    font-size: x-large;
}

.mobile-menu-button {
    width: 8rem;
    background-color: $background-color;
    border: none;

    &:hover {
        background-color: #efefef;
    }
}

.join-button-wrapper {
    padding: 1.5rem 10rem 0rem 0rem;
    margin-bottom: 0;
}

#join-button {
    --bs-btn-border-radius: 50px;
    --bs-btn-border-width: 0;
    --bs-btn-bg: #e76f51;
    --bs-btn-hover-bg: #EE9781;
    padding: 0.5rem 2rem 0.5rem 2rem;
    white-space: nowrap;
    letter-spacing: 0.25rem;
}

.footer-wrapper {
    display: grid;
    place-items: center;
    background-color: $content-color;
}

.greeting-mobile {
    font-weight: 400;
    text-align: start;
    font-size: xx-large;
}

.social-icon {
    color: $text-color;
    text-decoration: none;
    text-shadow: 4px 4px rgba(0.1, 0.1, 0.1, 0.2);
    transition: transform 0.2s ease;

    &:hover {
        color: #EE9781;
        text-decoration: none;
        text-shadow: 4px 4px rgba(0.1, 0.1, 0.1, 0.4);
        transform: scale(1.1) translateY(-1px);
        transition: transform 0.2s ease;
    }
}

.mobile-header-wrapper {
    background-color: $content-color;
    border-radius: 0px 0px 5px 5px;
}

.header-content-wrapper {
    height: 7rem;
    background-color: $content-color;
    border-radius: 0px 0px 5px 5px;
}

@media only screen and (max-width: 750px) {
    .header-content-wrapper {
        background-color: white;
        height: fit-content;
    }
}

.headerfull-menuitems-wrapper {
    padding: .2rem 0rem 0rem 0rem;
    margin-top: 0;
    background-color: #ecd3ac;
}

.menu-item {
    text-decoration: none;
    color: black;
    font-family: $logo-font;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    transition: transform 0.2s ease;

    &:hover {
        color: white;
        cursor: pointer;
        font-weight: bold;
        transform: scale(1.1) translateY(-1px);
        transition: transform 0.2s ease;
    }
}

.menu-item-mobile {
    text-decoration: none;
    color: black;
    font-family: $text-font;
    font-size: larger;
    text-align: center;
    letter-spacing: 2px;
    padding: 0.3rem;
    width: 5rem;
    margin-bottom: 0.5rem;

    &:hover {
        color: white;
        cursor: pointer;
    }
}

.footer-link {
    text-decoration: none;
    color: #222;
    transition: transform 0.2s ease;
    display: inline-block;

    &:hover {
        color: #222;
        cursor: pointer;
        transform: scale(1.1) translateY(-1px);
        transition: transform 0.2s ease;
    }
}

.page-content-wrapper {
    background-color: #fbf6ee;
    padding: 0rem 3rem 3rem 3rem;
    border-radius: 15px;
    margin-top: 3rem;
}

.contact-text {
    padding-left: 2rem;
}

.border {
    border: 5px solid red;
}

.mobile-menu-button {
    font-size: 2rem;
    background-color: $content-color;

    &:hover {
        color: $button-color;
        background-color: $content-color;
    }
}

.post-wrapper {
    max-height: 15rem;
    overflow: hidden;
    border-radius: 10px;
    background-color: $content-color;
    font-family: $text-font;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

    &:hover {
        color: $wrapper-color;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
        cursor: pointer;
    }
}

.header-logo {
    height: 6rem;
    width: auto;
    padding-right: 0;
    margin-right: 0;
}

.header-logo-mobile {
    width: auto;
}

@media only screen and (max-width: 450px) {
    .header-logo-mobile {
        height: 4rem;
    }
}

.header-mobile-main {
    white-space: nowrap;
    text-align: start;
    font-size: x-large;
    padding-top: 1.2rem;
}

@media only screen and (max-width: 680px) {
    .header-mobile-main {
        font-size: large;
        padding-top: 1.6rem;
    }
}

@media only screen and (max-width: 550px) {
    .header-mobile-main {
        font-size: medium;
        padding-top: .8rem;
    }
}

.event-wrapper {
    overflow: hidden;
    border-radius: 10px;
    background-color: $content-color;
    font-family: $text-font;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    padding: 4rem 1rem 4rem 1rem;
    margin: 1rem 1rem 2rem 1rem;
}

.post-title {
    font-family: $logo-font;
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
}

.post-title-on-mobile {
    font-family: $logo-font;
    font-size: 1.7rem;
    letter-spacing: 0.1rem;
}

.landing-line {
    font-family: $logo-font;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    margin-bottom: 1rem;
}

.text {
    font-family: $text-font;
    font-size: large;
    text-align: justify;
    line-height: 2rem;
}

.profile-pic-description {
    font-family: $text-font;
    font-size: medium;
    line-height: 1.5rem;
}

#form-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 1rem;
}

.textarea-input {
    resize: none;
}

.about-pic {
    width: 150%;
    height: 35rem;
    object-fit: cover;
    border-radius: 10px;
}

@media only screen and (max-width: 800px) {
    .about-pic {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        border-radius: 5px;
    }
}

.health-para {
    margin: .5rem 0rem .5rem 0rem;
}

.markDownText {
    text-align: justify;
    font-family: $text-font;
    font-size: larger;
}

.landing-pic {
    height: 35rem;
    width: 100%;
    object-fit: cover;
    object-position: 100%;
    border-radius: 10px;
}

@media only screen and (max-width: 1100px) {
    .landing-pic {
        height: 30rem;
        width: 100%;
        object-fit: cover;
        object-position: 60%;
    }
}

.profile-pic {
    width: 14rem;
    height: 14rem;
    object-fit: cover;
    border-radius: 100%;
    margin-bottom: 1.5rem;
}

.subtitles {
    font-size: x-large;
}

span.lazy-load-image-background {
    color: transparent;
    display: flex !important;
    width: 100%;
    object-fit: cover;
}

.border-right {
    border-right: 1px solid #222;
}

.yritused-headline {
    font-family: $logo-font;
}

.join-link-noscale {
    text-decoration: none;
    color: blue;
    transition: transform 0.2s ease-out;
    display: inline-block;

    &:hover {
        color: darkblue;
    }
}

.scroll-to-chapter {
    scroll-margin-top: 150px;
}

.litters-table {
    margin-top: 1rem;
    text-align: center;
    min-width: 100%;
}

.litters-table th {
    padding: 4px 8px 4px 8px;

}

.litters-table td {
    padding: 4px 8px 4px 8px;
    min-width: fit-content;
    white-space: nowrap;

}

table .empty {
    height: 5px;

}

.litters-table td:nth-child(even),
.litters-table th:nth-child(even) {
    border-style: none solid;
    border-color: rgba(0.5, 0.5, 0.5, 0.3);
    border-width: 2px;
}


.table-div {
    min-width: 100%;
}

@media only screen and (max-width: 900px) {
    .table-div {
        min-width: 100%;
        overflow-x: scroll;
    }
}

#accordion-override {
    --bs-accordion-active-bg: #ecd3ac;
    --bs-accordion-border-color: #ecd3ac;
    --bs-accordion-btn-focus-border-color: #ecd3ac;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #ecd3ac;
    --bs-accordion-btn-bg: #fbf6ee;
    --bs-body-bg: #fbf6ee;
}